import {
  QueryFunctionContext,
  useQuery,
  MutationFunction,
  useQueryClient,
  useMutation,
  UseMutateAsyncFunction,
  InvalidateQueryFilters,
} from '@tanstack/react-query'
import { serializeParameter } from '@src/services/http-common'
import { TerritoryFilter } from '@src/data/types/Filter'
import { Territory } from '../../types/Territory'
import { getApiClient } from '../api-client'

const SCOPETERRITORIES = 'territories'
const LIST = 'list'
const DETAIL = 'detail'

const keysFactory = {
  allTerritories: () => [{ scope: SCOPETERRITORIES, entity: LIST }] as const,
  territoriesList: (filters: TerritoryFilter) => [{ scope: SCOPETERRITORIES, entity: LIST, ...filters }] as const,
  allTerritoriesDetails: () => [{ scope: SCOPETERRITORIES, entity: DETAIL }] as const,
  territoryDetails: (id: string) => [{ scope: SCOPETERRITORIES, entity: DETAIL, id }] as const,
}

const getTerritory = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['territoryDetails']>>) => {
  const [{ id }] = queryKey
  if (!id) {
    return null
  }
  const apiClient = getApiClient()
  const response = await apiClient.get<Territory>(`/Territories/${id}`)
  return response.data
}
export function useTerritoryById(id: string | null): [Territory | null, boolean, Error | null] {
  const { data, isFetching, error } = useQuery({
    queryKey: keysFactory.territoryDetails(id ?? ''),
    queryFn: getTerritory,
    enabled: !!id,
  })
  return [data ?? null, isFetching, error]
}

const getTerritoryList = async ({
  queryKey: [filters],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['territoriesList']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<Territory[]>('/Territories', {
    params: filters,
    paramsSerializer(params) {
      return serializeParameter(params)
    },
  })

  return response.data
}

export function useTerritoriesList(filter: TerritoryFilter): [Territory[], boolean] {
  const { data, isFetching } = useQuery({
    queryKey: [...keysFactory.territoriesList(filter)],
    queryFn: getTerritoryList,
    initialData: [],
  })

  return [data ?? [], isFetching]
}

const createTerritory: MutationFunction<Territory, Partial<Territory>> = async (newTerritory) => {
  const apiClient = getApiClient()
  const response = await apiClient.post('/Territories', newTerritory)
  return response.data as Territory
}

export function useCreateTerritory(): [
  UseMutateAsyncFunction<Territory, Error, Partial<Territory>, unknown>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: createTerritory,
    onSuccess: async () => {
      const filters: InvalidateQueryFilters = { queryKey: keysFactory.allTerritories() }
      await queryClient.invalidateQueries(filters)
    },
  })

  return [mutateAsync, isPending, reset]
}

const deleteTerritory = async (territoryId: string) => {
  const apiClient = getApiClient()
  await apiClient.delete(`/Territories/${territoryId}`)
}

export function useDeleteTerritory(): [MutationFunction<void, string>, boolean] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteTerritory,
    onSuccess: (_, territoryId) => {
      const previousTerritories = queryClient.getQueryData<Territory[]>(keysFactory.allTerritories())

      if (previousTerritories) {
        const updatedTerritories = previousTerritories.filter((territory) => territory.id !== territoryId)
        queryClient.setQueryData(keysFactory.allTerritories(), updatedTerritories)
      }
    },
  })

  return [mutateAsync, isPending]
}
const updateTerritory: MutationFunction<Territory, Partial<Territory>> = async (territory: Partial<Territory>) => {
  const apiClient = getApiClient()

  if (!territory.id) {
    throw new Error('Territory ID is required')
  }

  const response = await apiClient.put(`/Territories/${territory.id}`, territory)
  return response.data as Territory
}

export function useUpdateTerritory(): [
  UseMutateAsyncFunction<Territory, Error, Partial<Territory>, unknown>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()

  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updateTerritory,
    onSuccess: async (data) => {
      const filters: InvalidateQueryFilters = { queryKey: keysFactory.allTerritories() }
      await queryClient.invalidateQueries(filters)
      queryClient.setQueryData(keysFactory.territoryDetails(data.id), data)
    },
  })

  return [mutateAsync, isPending, reset]
}
